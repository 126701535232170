import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EntityAssessedDetailsHeader } from './components/entity-assessed-details-header'
import { EntityAssessedDetailsSummary } from './components/entity-assessed-details-summary'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { getEntityAssessedDetails } from './get-entity-assessed-details-slice'
import { AnilityBackdrop } from '../../../components/anility-backdrop'
import EntityAssessedDetailsAssessment from './entity-assessed-assessments/entity-assessed-details-assessment'
import { setPageNumber } from './get-entity-assessed-users-slice'
import { usePermission } from '../../../hooks/use-permission'
import { AnilityScopes } from '../../../interfaces/anility-scopes'

const EntityAssessedDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [readReports, readEntityAssessed] = usePermission([AnilityScopes.Read.Reports, AnilityScopes.Read.EntityAssessed])
  const isViewProfileVisible = readEntityAssessed

  const {
    getEntityAssessedDetailsState,
    getOrderedAssessmentRequestsState,
    getUnderReviewAssessmentRequestsState,
    getReportWorkspacesState,
    getCompletedAssessmentRequestsState,
    getOnHoldAssessmentState,
    getExpiredAssessmentRequestsState,
    getAnalystUsersState,
    assignAnalystUserToAssessmentRequestsState,
    updateExpiredAssessmentState,
    reactivateDeclinedAssessmentRequestState
  } = useAppSelector(state => state)

  const loading = getEntityAssessedDetailsState.loading === 'loading' ||
    getOrderedAssessmentRequestsState.loading === 'loading' ||
    getUnderReviewAssessmentRequestsState.loading === 'loading' ||
    getReportWorkspacesState.loading === 'loading' ||
    getCompletedAssessmentRequestsState.loading === 'loading' ||
    getOnHoldAssessmentState.loading === 'loading' ||
    getExpiredAssessmentRequestsState.loading === 'loading' ||
    getAnalystUsersState.loading === 'loading' ||
    assignAnalystUserToAssessmentRequestsState.loading === 'loading' ||
    updateExpiredAssessmentState.loading === 'loading' ||
    reactivateDeclinedAssessmentRequestState.loading === 'loading'

  const refreshEntityAssessedDetails = () => {
    if (!id || isNaN(+id)) return

    dispatch(getEntityAssessedDetails({
      entityAssessedId: +id
    }))
    dispatch(setPageNumber(1))
  }

  const handleViewProfile = () => {
    if (getEntityAssessedDetailsState.entityAssessedDetails?.profileLink) {
      window.open(getEntityAssessedDetailsState.entityAssessedDetails?.profileLink, '_blank')
    }
  }

  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate('..')
    } else {
      refreshEntityAssessedDetails()
    }
  }, [id])

  return (
    <Box p={4} sx={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      position: 'relative',
      rowGap: 2
    }}>
      <EntityAssessedDetailsHeader entityAssessedDetails={getEntityAssessedDetailsState.entityAssessedDetails} onViewProfile={isViewProfileVisible ? handleViewProfile : undefined} />
      <EntityAssessedDetailsSummary entityAssessedDetails={getEntityAssessedDetailsState.entityAssessedDetails} refreshEntityAssessedDetails={refreshEntityAssessedDetails} />
      {readReports && id && (<EntityAssessedDetailsAssessment entityAssessedId={+id} />)}
      <AnilityBackdrop open={loading} />
    </Box>
  )
}

export default EntityAssessedDetails
